// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../images/GettyImages-1124486450_2p2mb_quality.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body {\n  margin: 0;\n  background-color: white;\n  font-size: 1rem;\n}\n\n.main {\n  color: black;\n}\n\n.backgroundImage {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  height: 100%;\n  display: block;\n}\n\n.main.top,\n.oneMinuteSubscribe {\n  color: white;\n  text-align: center;\n}\n\nli {\n  list-style: none;\n}\n\n.form-control {\n  display: block;\n  width: 100%;\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  line-height: 1.5;\n  color: #495057;\n  background-color: #fff;\n  background-clip: padding-box;\n  border: 1px solid #ced4da;\n  border-radius: 0.25rem;\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n}\n\n.container {\n  width: 100%;\n  padding-right: 15px;\n  padding-left: 15px;\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.line-clamp {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n}\n\n.footer-nav {\n  padding: 12px 12px;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-wrap: wrap;\n  flex-wrap: wrap;\n  -webkit-box-pack: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n}\n\nol li {\n  list-style-type: decimal;\n}\n\n.accordion .card-header button:before {\n  padding-right: 1em;\n  font-family: 'FontAwesome';\n  content: '\\f068';\n  float: left;\n}\n\n.accordion .card-header button.collapsed:before {\n  content: '\\f067';\n}\n\ndiv.privacy h4,\ndiv.term h4 {\n  margin-top: 1.5em;\n}\n\ndiv.qnas h4,\ndiv.privacy h4,\ndiv.term h4 {\n  margin-bottom: 0.5em;\n}\n", ""]);
// Exports
module.exports = exports;
