import React, { useState } from 'react';
import PageLink from '../components/PageLink';
import menuPages from '../contents/menuPages';
import NavPageLink from './NavPageLink';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
} from 'reactstrap';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const pagelinks = menuPages.map((page) => (
    <NavItem key={page}>
      <NavPageLink page={page} />
    </NavItem>
  ));

  return (
    <Navbar
      fixed="top"
      expand="md"
      color="dark"
      dark
      onClick={() => {
        if (isOpen) toggle();
      }}
    >
      <NavbarBrand tag={PageLink} page="TOP">
        東大生フリーランス
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        <Nav className="mr-auto" navbar>
          {pagelinks}
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Header;
