import { createReducer } from '../redux-extension';
import subscribedItems from '../contents/subscribedItems';
import emailjs from 'emailjs-com';
import { isDeveloped } from '../config';

let initialState = {};
for (const key in subscribedItems) {
  if (subscribedItems.hasOwnProperty(key)) {
    initialState[key] = '';
  }
}

export default createReducer(initialState, {
  CHANGE_SUBSCRIBE: (state, action) => {
    return {
      ...state,
      [action.key]: action.value,
    };
  },
  SEND_SUBSCRIBE: (state) => {
    let subscribedInfo = {};
    for (let [key, value] of Object.entries(state)) {
      subscribedInfo[subscribedItems[key].name] = value;
    }

    let templateID = isDeveloped
      ? 'freelance_skewers_test'
      : 'freelance_skewers';

    fetch('https://hooks.slack.com/services/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', {
      method: 'POST',
      body: JSON.stringify({
        text: JSON.stringify(subscribedInfo)
      })
    })
    // emailjs
    //   .send(
    //     'gmail',
    //     templateID,
    //     { subscribedInfo: JSON.stringify(subscribedInfo) },
    //     'user_EJI7EK9OaVLFoWCYLZ5PM'
    //   )
    //   .then(
    //     function (response) {
    //       if (isDeveloped)
    //         console.log('SUCCESS!', response.status, response.text);
    //     },
    //     function (err) {
    //       if (isDeveloped) console.log('FAILED...', err);
    //     }
    //   );
    return state;
  },
});
