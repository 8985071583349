import React from 'react';

const flows = [
  {
    title: '登録',
    body: (
      <p>
        下のメニューにある登録ボタンを押して、メールアドレスなどを登録します。
        登録後、近日中に担当者よりご連絡差し上げます。
        <br />
        メールアドレスのみでの登録も可能です。当サービスは無料でご利用できます。
        <br />
        <br />
        バイト未経験の方やプログラミング未経験の方でもご安心ください。
        <br />
        当社でのトレーニングを行うなど、あなたに合うプランを提示致します。
        <br />
        <br />
        機械学習など高度な知識を活用したいという方にも、登録者様のご興味にあった案件を紹介させていただきます。
      </p>
    )
  },
  {
    title: 'カウンセリング',
    body: (
      <p>
        メールにて、登録者様のスキルや学生生活との兼ね合い、卒業後のプランなどのご希望を伺います。
        <br />
        <br />
        経験に不安がある方には当社でのトレーニングを行います。トレーニングの場合も単金を出させていただく場合があります。
        <br />
        その後、案件の紹介をさせていただきます。
        <br />
        案件にご参画するスキルが十分にある場合には、登録者様のご希望に合わせた案件を紹介させていただきます。
      </p>
    )
  },
  {
    title: 'リクルート',
    body: (
      <p>
        案件先の企業様と弊社が、登録者様に同意頂いた内容で契約を結びます。
        <br />
        登録者様の面接等は不要です。
        <br />
        <br />
        案件参画中においても、登録者様の実力が発揮できるようサポートさせていただきます。
      </p>
    )
  }
];

export default flows;
