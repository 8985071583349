import React from 'react';
import OneMinuteLink from './OneMinuteLink';

const Top = () => (
  <div className="pt-sm-5">
    <h1>東大生フリーランス</h1>

    <h2 className="pt-4">フリーランスエンジニアになりたい東大生へ</h2>
    <p className="lead">
      プログラミングは自信あるけどいきなりバイトは不安...
      <br />
      エンジニア就活に向けてスキルを付けておきたい...
      <br />
      やる気はあるけど何をすればいいか分からない...
      <br />
      <br />
      登録すれば、すぐに解決します。
    </p>
    <br />
    <OneMinuteLink />
  </div>
);

export default Top;
