import React from 'react';
import flows from '../contents/flows';

const PageFlow = () =>
  flows.map(({ title, body }, index) => (
    <div key={title} className="pb-3">
      <h3 className="font-weight-normal pb-2 mb-2 border-bottom">
        {index + 1 + '. ' + title}
      </h3>
      {body}
    </div>
  ));

export default PageFlow;
