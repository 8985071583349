import React from 'react';
import SubscribeForm from '../containers/SubscribeForm';
import pages from '../contents/pages';

const Subscribe = () => (
  <div>
    <SubscribeForm />
  </div>
);

export default Subscribe;
