import React from 'react';

const terms = [
  {
    title: '適用',
    body: (
      <div>
        <ol>
          <li>
            本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
          </li>
          <li>
            当社は、ユーザーの承諾を得ることなく、当社が適当と判断する方法でユーザーに通知することにより、本規約を変更できるものとします。
          </li>
          <li>
            当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，本規定の規定が優先されるものとします。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '利用登録',
    body: (
      <div>
        <ol>
          <li>
            本サービスにおいては，登録希望者が本規約に同意の上，当社の定める方法によって利用登録を申請し，当社がこれを承認することによって，利用登録が完了するものとします。
          </li>
          <li>
            当社は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
            <ol>
              <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
              <li>本規約に違反したことがある者からの申請である場合</li>
              <li>その他，当社が利用登録を相当でないと判断した場合</li>
            </ol>
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: 'サービスの提供',
    body: (
      <div>
        <ol>
          <li>
            本サービスは以下を提供します。
            <ol>
              <li>
                ユーザーからいただいたお申込み内容とご相談内容を「「利用企業から受領する（todo)」」求人条件と照合し、照合結果に基づいて求人情報を提供すること。
              </li>
              <li>その他ユーザーに有益と当社が判断する一切のサービス</li>
            </ol>
          </li>
          <li>本サービスはどなたでも利用することができます。</li>
          <li>
            当社は、ユーザーに通知することなく、いつでも本サービスの仕様、機能又は内容を変更することができるものとします。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '連絡又は通知',
    body: (
      <div>
        <ol>
          <li>
            ユーザーから当社に対する連絡、並びに当社からユーザーに対する連絡又は通知は、当社の定める方法で行うものとします。
          </li>
          <li>
            ユーザーに対する当社からの通知は、本サービスに登録されているユーザーのメールアドレスにメールを送信することをもって通知したものとみなします。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '禁止事項',
    body: (
      <div>
        ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。
        <ol>
          <li>法令または公序良俗に違反する行為</li>
          <li>犯罪行為に関連する行為</li>
          <li>
            本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
          </li>
          <li>
            当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為
          </li>
          <li>本サービスによって得られた情報を商業的に利用する行為</li>
          <li>当社のサービスの運営を妨害するおそれのある行為</li>
          <li>不正アクセスをし，またはこれを試みる行為</li>
          <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
          <li>不正な目的を持って本サービスを利用する行為</li>
          <li>
            本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為
          </li>
          <li>他のユーザーまたはその他の第三者に成りすます行為</li>
          <li>
            当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為
          </li>
          <li>その他，当社が不適切と判断する行為</li>
        </ol>
      </div>
    ),
  },
  {
    title: '退会',
    body: (
      <div>
        ユーザーは、当社所定の手続きを行うことにより、いつでも利用登録を抹消し、退会することができます。
      </div>
    ),
  },
  {
    title: '保証の否認および免責事項',
    body: (
      <div>
        <ol>
          <li>
            当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
          </li>
          <li>
            当社は，本サービスに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
          </li>
          <li>
            前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '利用規約の変更',
    body: (
      <div>
        当社は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本サービスの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
      </div>
    ),
  },
  {
    title: '個人情報の取扱い',
    body: (
      <div>
        当社は，本サービスの利用によって取得する個人情報については，当社「プライバシーポリシー」に従い適切に取り扱うものとします。
      </div>
    ),
  },
  {
    title: '権利義務の譲渡の禁止',
    body: (
      <div>
        ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。
      </div>
    ),
  },
  {
    title: '準拠法・裁判管轄',
    body: (
      <div>
        <ol>
          <li>本規約の解釈にあたっては，日本法を準拠法とします。</li>
          <li>
            本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
          </li>
        </ol>
      </div>
    ),
  },
];

export default terms;
