import React from 'react';
import FooterLink from './FooterLink';

const Footer = () => (
  <div className="text-center border-top border-dark">
    <nav className="footer-nav">
      <FooterLink page="TERM" />
      <FooterLink page="PRIVACY" />
      <FooterLink page="COMPANY" />
    </nav>
    <p>© 2020 Skewers</p>
  </div>
);

export default Footer;
