const subscribedItems = {
  mail: {
    name: 'メールアドレス',
    isRequired: true,
    example: 'name@example.com',
  },
  name: {
    name: '氏名',
    example: '田中　太郎',
  },
  nameKana: {
    name: '氏名カナ',
    example: 'タナカ　タロウ',
  },
  birthday: {
    name: '生年月日',
    example: '1999/01/01',
  },
  address: {
    name: '住所',
    example: '東京都千代田区神田小川町２−８',
  },
  phone: {
    name: '電話番号',
    example: '000-0000-0000',
  },
  startdateDesired: {
    name: '希望案件開始時期',
    example: '2020/01/01',
  },
};

export default subscribedItems;
