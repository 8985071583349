import React from 'react';
import { Link } from 'react-router-dom'; // react-router v4/v5

const resLink = ({ to, onClick, className, isActive, children }) => (
  <Link
    to={to}
    onClick={() => {
      window.scrollTo(0, 0);
      onClick && onClick();
    }}
    className={className + (isActive ? ' active' : '')}
  >
    {children}
  </Link>
);

export default resLink;
