import React from 'react';
import { NavLink } from 'react-router-dom';
import pages from '../contents/pages';

const NavPageLink = ({ page, className, children }) =>
  pages[page].isExternal ? (
    <div></div>
  ) : (
    <NavLink
      exact
      to={pages[page].path}
      onClick={() => {
        window.scrollTo(0, 0);
      }}
      className={'nav-link' + (className ? ' ' + className : '')}
    >
      {children || pages[page].name}
    </NavLink>
  );

export default NavPageLink;
