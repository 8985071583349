import React from 'react';

const policies = [
  {
    title: '個人情報',
    body: (
      <div>「個人情報」とは、個人情報保護法にいう「個人情報」を指します。</div>
    ),
  },
  {
    title: '個人情報の収集方法',
    body: (
      <div>
        当社は、ユーザーが利用登録をする際に氏名、生年月日、住所、電話番号、メールアドレスなどの個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む情報を,当社の提携先（以下、「提携先」といいます。）などから収集することがあります。
      </div>
    ),
  },
  {
    title: '個人情報を収集・利用する目的',
    body: (
      <div>
        当社が個人情報を収集・利用する目的は、以下のとおりです。
        <ol>
          <li>当社サービスの提供・運営のため</li>
          <li>ユーザーからのお問い合わせに回答するため</li>
          <li>
            本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
          </li>
          <li>上記の利用目的に付随する利用目的のため</li>
        </ol>
      </div>
    ),
  },
  {
    title: '利用目的の変更',
    body: (
      <div>
        <ol>
          <li>
            当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
          </li>
          <li>
            利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、ユーザーに通知し、または本ウェブサイト上に公表するものとします。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '個人情報の第三者提供',
    body: (
      <div>
        <ol>
          <li>
            当社は、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
          </li>
          <li>
            前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
            <ol>
              <li>
                当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              </li>
              <li>
                合併その他の事由による事業の承継に伴って個人情報が提供される場合
              </li>
              <li>
                個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめユーザーに通知し、またはユーザーが容易に知り得る状態に置いた場合
              </li>
            </ol>
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '個人情報の開示',
    body: (
      <div>
            当社は、ユーザーから個人情報の開示を求められたときは、ユーザーに対し、遅滞なくこれを開示します。ただし、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1、000円の手数料を申し受けます。
      </div>
    ),
  },
  {
    title: '個人情報の訂正および削除',
    body: (
      <div>
        <ol>
          <li>
            ユーザーは、当社の保有する自己の個人情報が誤った情報である場合には、当社が定める手続きにより、当社に対して個人情報の訂正、追加または削除（以下、「訂正等」といいます。）を請求することができます。
          </li>
          <li>
            当社は、ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正等を行い、その旨をユーザーに通知します。
          </li>
          <li>
            個人情報保護法その他の法令により、当社が訂正等の義務を負わない場合は、前2項の規定は適用されません。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: '個人情報の利用停止等',
    body: (
      <div>
        <ol>
          <li>
            当社は、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
          </li>
          <li>
            前項の調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行い、その旨をユーザーに通知します。
          </li>
          <li>
            個人情報保護法その他の法令により、当社が利用停止等の義務を負わない場合は、前2項の規定は適用されません。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: 'プライバシーポリシーの変更',
    body: (
      <div>
        <ol>
          <li>
            本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。
          </li>
          <li>
            当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: 'お問い合わせ窓口',
    body: (
      <div>
        <div>
          本ポリシーに関するお問い合わせは、下記の窓口までお願いいたします。
        </div>
        <div>
          東京都千代田区神田小川町２−８ サニーセントラルビル５階
          <br />
          株式会社Skewers
          <br />
          contact@skewers.me
        </div>
      </div>
    ),
  },
];

export default policies;
