const qnas = [
  {
    question: 'バイト未経験なのですが大丈夫ですか？',
    answer:
      '不安がある場合などは、案件紹介前に当社での簡単なお仕事を割り振るか、トレーニングを実施致します。場合によってはすぐに案件を紹介致しますので、プログラミング経験がある場合は遠慮なくお伝えください。'
  },
  {
    question: 'プログラミング未経験なのですが大丈夫ですか？',
    answer:
      '案件紹介前に当社にてトレーニングを実施致します。案件を紹介するに十分な実力が身に付いてから、登録者様のレベルに合わせた案件を紹介致します。'
  },
  {
    question: '実際に参画できるのは登録してからどれくらいかかりますか？',
    answer:
      'ご希望次第では登録して１週間後に案件先に参画していただくことができます。'
  },
  {
    question: '週１でしか入れないのですが。',
    answer:
      '登録者様のご希望に合わせた案件を紹介致しますので、頻度が低かったりしても問題ございません。'
  }
];

export default qnas;
