import {
  PageTop,
  PageQandA,
  PageFlow,
  PageSubscribe,
  PageSubscribeSuccess,
  PageTerm,
  PagePrivacy,
} from '../components';

const pages = {
  TOP: {
    path: '/',
    name: 'TOP',
    component: PageTop,
  },
  FLOW: {
    path: '/flow',
    name: 'ご利用の流れ',
    component: PageFlow,
  },
  Q_AND_A: {
    path: '/qna',
    name: 'Q & A',
    component: PageQandA,
  },
  SUBSCRIBE: {
    path: '/register',
    name: '登録',
    component: PageSubscribe,
  },
  SUBSCRIBE_SUCCESS: {
    path: '/register_success',
    name: '登録',
    component: PageSubscribeSuccess,
  },
  TERM: {
    path: '/term',
    name: '利用規約',
    component: PageTerm,
  },
  PRIVACY: {
    path: '/privacy_policy',
    name: 'プライバシーポリシー',
    component: PagePrivacy,
  },
  COMPANY: {
    path: 'http://skewers.be/',
    name: '会社情報',
    isExternal: true,
  },
};

export default pages;
