import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Header from '../components/Header';

import pages from '../contents/pages';

const MainBody = ({ page, val }) => {
  const classNameBg =
    page === 'TOP' ? 'min-vh-100 backgroundImage' : 'bg-white';

  return (
    <div className={classNameBg}>
      <div className="pt-5"></div>

      <div className={'main py-4' + (page === 'TOP' ? ' top' : '')}>
        <div className="container">
          {page === 'TOP' ? (
            <></>
          ) : (
            <h2 className="font-weight-light pb-2 mb-3 border-bottom border-dark">
              {val.name}
            </h2>
          )}
          <val.component />
        </div>
      </div>
    </div>
  );
};

const Main = () => {
  const routes = Object.entries(pages).map(([page, val]) => (
    <Route key={page} exact path={val.path}>
      <MainBody page={page} val={val} />
    </Route>
  ));

  return (
    <Switch>
      {routes}
      <Redirect to={pages.TOP.path}>
        <MainBody page="TOP" val={pages.TOP} />
      </Redirect>
      <Route>
        <MainBody page="TOP" val={pages.TOP} />
      </Route>
    </Switch>
  );
};

export default Main;
