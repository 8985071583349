import React from 'react';
import qnas from '../contents/qnas';

const QandA = () => {
  const items = qnas.map((qna, i) => (
    <li key={i} className="mb-5">
      <h4>{qna.question}</h4>
      <p>{qna.answer}</p>
    </li>
  ));

  return (
    <div className="qnas">
      <ul className="p-0">{items}</ul>
    </div>
  );
};

export default QandA;
