import React from 'react';

const PageSubscribeSuccess = () => (
  <div className="py-5 text-center">
    <h2>登録完了</h2>
    <p className="lead">後日、メールにてご連絡差し上げます。</p>
  </div>
);

export default PageSubscribeSuccess;
