import React from 'react';
import Link from './Link';
import pages from '../contents/pages';

const OneMinuteLink = () => {
  return (
    <Link className="btn btn-primary btn-lg" to={pages.SUBSCRIBE.path}>
      １分で登録！
    </Link>
  );
};

export default OneMinuteLink;
