import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducers';
import { devToolsEnhancer } from 'redux-devtools-extension/logOnlyInProduction';

export const history = createBrowserHistory();

export default function configureStore() {
  const store = createStore(
    createRootReducer(history),
    compose(
      applyMiddleware(
        routerMiddleware(history) // for dispatching history actions
      ),
      devToolsEnhancer()
    )
  );

  return store;
}
