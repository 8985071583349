import React from 'react';
import terms from '../contents/terms';

const PageTerm = () => (
  <div className="term">
    <p>
      この利用規約（以下、「本規約」といいます。）は，株式会社Skewers（以下、「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
    </p>
    {terms.map(({ title, body }, index) => (
      <div key={index}>
        <h4>
          第{index + 1}条（{title}）
        </h4>
        {body}
      </div>
    ))}
  </div>
);

export default PageTerm;
