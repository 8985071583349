import React from 'react';
import privacy from '../contents/privacy';

const PagePrivacy = () => (
  <div className="privacy">
    <p>
      株式会社Skewers（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。
    </p>
    {privacy.map(({ title, body }, index) => (
      <div key={index}>
        <h4>
          第{index + 1}条（{title}）
        </h4>
        {body}
      </div>
    ))}
  </div>
);

export default PagePrivacy;
