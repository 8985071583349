import React from 'react';
import Link from './Link';
import pages from '../contents/pages';

const PageLink = ({ page, className, isActive, children }) =>
  pages[page].isExternal ? (
    <a
      href={pages[page].path}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      {pages[page].name}
    </a>
  ) : (
    <Link to={pages[page].path} isActive={isActive} className={className}>
      {children || pages[page].name}
    </Link>
  );

export default PageLink;
