import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import subscribedItems from '../contents/subscribedItems';
import PageLink from '../components/PageLink';
import pages from '../contents/pages';

// https://gist.github.com/Victa/3937605
function is_email(id) {
  return /^([\w!.%+\-\*])+@([\w\-])+(?:\.[\w\-]+)+$/.test(id);
}

const SubscribeForm = ({ values, onChange, onSubmit, history }) => {
  let formsRequired = [];
  let forms = [];
  for (let [itemKey, itemValue] of Object.entries(subscribedItems)) {
    if (itemValue.isRequired) {
      formsRequired.push(
        <div key={itemKey} className="form-group">
          <label htmlFor={itemKey + 'InputSubscribed'}>{itemValue.name} </label>
          <input
            type={itemKey}
            value={values[itemKey]}
            onChange={(event) => onChange(itemKey, event.target.value)}
            className="form-control"
            id={itemKey + 'InputSubscribed'}
            placeholder={itemValue.example}
            aria-describedby={itemKey + 'HelpSubscribed'}
          />
        </div>
      );
    } else {
      forms.push(
        <div key={itemKey} className="form-group">
          <label htmlFor={itemKey + 'InputSubscribed'}>{itemValue.name} </label>
          <input
            type={itemKey}
            value={values[itemKey]}
            onChange={(event) => onChange(itemKey, event.target.value)}
            className="form-control"
            id={itemKey + 'InputSubscribed'}
            placeholder={itemValue.example}
            aria-describedby={itemKey + 'HelpSubscribed'}
          />
        </div>
      );
    }
  }

  let button = (
    <button
      type="submit"
      className="btn btn-primary"
      onClick={(e) => {
        e.preventDefault();
        if (is_email(values.mail)) {
          onSubmit();
          history.push(pages.SUBSCRIBE_SUCCESS.path);
        }
      }}
    >
      登録
    </button>
  );

  return (
    <div>
      <form>
        {formsRequired}
        <p>
          登録前には
          <PageLink page="TERM" />
          と
          <PageLink page="PRIVACY" />
          をご確認ください。
        </p>
        {button}
        <div className="accordion mt-3" id="accordionOptional">
          <div className="card-header bg-secondary" id="headingOptional">
            <h3 className="mb-0">
              <button
                className="btn btn-link btn-block p-0 text-left text-white collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                追加項目 <small>(任意)</small>
              </button>
            </h3>
          </div>

          <div
            id="collapseOne"
            className="collapse"
            aria-labelledby="headingOptional"
            data-parent="#accordionOptional"
          >
            <div className="card-body">
              {forms}
              {button}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  values: state.subscribe,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (key, value) => {
    dispatch({
      type: 'CHANGE_SUBSCRIBE',
      key: key,
      value: value,
    });
  },
  onSubmit: () => {
    dispatch({
      type: 'SEND_SUBSCRIBE',
    });
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SubscribeForm)
);
